import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Card, Alert, Container } from "react-bootstrap";

import { actionLogin } from "../actions/authSlice";

const Login = () => {
  const error = useSelector((state) => state.auth.error);
  const isLogged = useSelector((state) => state.auth.isLogged);
  const [login, setLogin] = useState({
    username: "usuario1@dominio.com",
    password: "password1",
  });
  const dispatch = useDispatch();

  const handleInputChange = (event) =>
    setLogin({
      ...login,
      [event.target.name]: event.target.value,
    });

  const submitForm = (event) => {
    event.preventDefault();
    console.log("enviando datos..." + login.username + " " + login.password);
    dispatch(actionLogin(login.username, login.password));
  };
  return (
    <Container>
      <Card>
        <Card.Header>Login</Card.Header>
        <Card.Body>
          <Form onSubmit={submitForm}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="username"
                value={login.username}
                onChange={handleInputChange}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={login.password}
                onChange={handleInputChange}
              />
            </Form.Group>
            {error.message && <Alert variant="danger">{error.message}</Alert>}
            {!isLogged && (
              <Button variant="primary" type="submit">
                Login
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
