import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  ListGroup,
  Row,
  Col,
  Alert,
  ButtonGroup, Pagination,
} from 'react-bootstrap';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import _ from 'lodash';

import { actionGetSymbols } from '../actions/catalogsSlice';
import { actionGetPendingWithdrawals } from '../actions/withdrawalListSlice';
import {
  actionApproveWithdrawal,
  actionRejectWithdrawal,
  clearError,
} from '../actions/withdrawalSlice';
import Retiro from '../components/Retiro';
import { currencyFormat } from '../config';

import ModalConfirmar from '../components/ModalConfirmar';

const ListaRetiros = () => {
  const pendingWitdrawalsList = useSelector(
    (state) => state.withdrawalList.pendingWitdrawalsList,
  );
  const pendingWitdrawalsListError = useSelector(
    (state) => state.withdrawalList.error,
  );
  const withdrawalError = useSelector((state) => state.withdrawal.error);
  const [ retiro, setRetiro ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ operacion, setOperacion ] = useState();
  const [ pageNumber, setPageNumber ] = useState(0);
  const [ itemsPerPage, setItemsPerPage ] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetPendingWithdrawals()); // Esto se va a disparar varias veces en development, pero no en produccion
    dispatch(actionGetSymbols());
  }, []);

  useEffect(() => {
    const maxPages = Math.ceil(pendingWitdrawalsList.length / itemsPerPage) - 1;
    if (pageNumber > maxPages) {
      setPageNumber(maxPages);
    }
  }, [ pendingWitdrawalsList ]);

  const askConfirmation = (tx, accion) => {
    setOperacion({ tx, accion });
    setModal(true);
  };

  const handleAccept = () => {
    if (operacion.accion === 'aprobar') {
      console.log('Aceptado aprobar');
      dispatch(actionApproveWithdrawal(operacion.tx.id));
      setRetiro(false);
    } else if (operacion.accion === 'cancelar') {
      console.log('Aceptado cancelar');
      dispatch(actionRejectWithdrawal(operacion.tx.id));
      setRetiro(false);
    }
    setModal(false);
  };

  const handleSelect = (retiro) => {
    setRetiro(retiro);
  };
  return (
    <>
      {withdrawalError.code > 0 && (
        <Alert
          variant="danger"
          dismissible
          onClose={() => dispatch(clearError())}
        >
          Hubo un error al ejecutar la operación: {withdrawalError.message}
        </Alert>
      )}
      <ModalConfirmar
        show={modal}
        title="Por favor confirma la acción"
        handleClose={() => setModal(false)}
        handleAccept={() => handleAccept()}
      >
        {operacion &&
          operacion.accion &&
          operacion.accion === 'cancelar' &&
          `Se requiere confirmar la CANCELACIÓN de la operacion ${
            operacion.tx.id
          } por ${currencyFormat(operacion.tx.amount)} ${operacion.tx.symbol}`}
        {operacion &&
          operacion.accion &&
          operacion.accion === 'aprobar' &&
          `Se requiere confirmar la APROBACIÓN de la operacion ${
            operacion.tx.id
          } por ${currencyFormat(operacion.tx.amount)} ${operacion.tx.symbol}`}
      </ModalConfirmar>
      <Card>
        <Card.Body>
          {pendingWitdrawalsList && pendingWitdrawalsList.length > 0 && (<Row>
            <Col sm={4}>
              <Pagination>
                <Pagination.First onClick={() => setPageNumber(0)}/>
                <Pagination.Prev onClick={() => setPageNumber(Math.max(0, pageNumber - 1))}/>
                {_.range(0, Math.ceil(pendingWitdrawalsList.length / itemsPerPage)).map((k) => (
                  <Pagination.Item key={k} active={pageNumber === k}
                                   onClick={() => setPageNumber(k)}>{k + 1}</Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => setPageNumber(Math.min(Math.ceil(pendingWitdrawalsList.length / itemsPerPage) - 1, pageNumber + 1))}/>
                <Pagination.Last
                  onClick={() => setPageNumber(Math.ceil(pendingWitdrawalsList.length / itemsPerPage) - 1)}/>
              </Pagination>
              <ListGroup>
                {pendingWitdrawalsList && pendingWitdrawalsList.length > 0 && (_.chunk(pendingWitdrawalsList, itemsPerPage)[Math.min(Math.ceil(pendingWitdrawalsList.length / itemsPerPage) - 1, pageNumber)] || []).map((p, k) => (
                  <ListGroup.Item action={true} onClick={() => handleSelect(p)} key={k} eventKey={k}>
                    <Row>
                      <Col md="3" className="text-end">
                        {currencyFormat(p.amount)}
                      </Col>
                      <Col md="2" className="text-end">
                        {p.symbol}
                      </Col>
                      <Col className="truncate text-start">
                        {p.username}
                      </Col>
                      <Col md="2">
                        <ButtonGroup size="sm" className="float-right">
                          <div className="btn btn-success btn-sm" onClick={() => askConfirmation(p, 'aprobar')}>
                            <FaThumbsUp/>
                          </div>
                          <div className="btn btn-danger btn-sm" onClick={() => askConfirmation(p, 'cancelar')}>
                            <FaThumbsDown/>
                          </div>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </ListGroup.Item>))}
              </ListGroup>
            </Col>
            <Col><Retiro retiro={retiro}/></Col>
          </Row>)}
          {pendingWitdrawalsListError.code > 0 && (
            <Alert variant="danger">{pendingWitdrawalsListError.message}</Alert>
          )}
          {(!pendingWitdrawalsList || !pendingWitdrawalsList.length) && (
            <Alert variant="warning">No hay retiros pendientes</Alert>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ListaRetiros;
