import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import MenuLateral from "../containers/MenuLateral";
import NavegacionPrincipal from "../containers/NavegacionPrincipal";

const Dashboard = () => {
  return (
    <Container fluid>
      <Row>
        <NavegacionPrincipal />
      </Row>
      <Row>
        <Col md={2}>
          <MenuLateral />
        </Col>
        <Col md={10}>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
