import { Button, Modal } from "react-bootstrap";
export default (props) => {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={props.handleCancel || props.handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={props.handleAccept || props.handleClose}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
