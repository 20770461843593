import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { currencyFormat } from '../config';
import { FaExclamation } from 'react-icons/fa';

export const UserLimitsUsage = ({ userLimits }) => {

  return (
    <>
      {!userLimits &&
        <Row><Col className="text-center"><Spinner animation="border" size="xl" variant="info"/></Col></Row>}
      {userLimits && <div>
        Lista de límites, incluyen la operación a aprobar o cancelar
        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>Moneda</th>
            <th>Operación</th>
            <th>Usado diario</th>
            <th>Límite diario</th>
            <th>Usado mensual</th>
            <th>Límite mensual</th>
          </tr>
          </thead>
          <tbody>
          {userLimits && Object.entries(userLimits).map(([ symbol, data ], k) => data && data.length && data.filter(f => f.dailyUsage > 0 || f.monthlyUsage > 0).map(item => (
            <tr key={`${symbol}:${item.transaction_type}`}
                className={(item.dailyLimit > 0 && item.dailyUsage >= item.dailyLimit || item.monthlyLimit > 0 && item.monthlyUsage >= item.monthlyLimit) && 'table-danger' || ''}>
              <td>{symbol}</td>
              <td>{item.transaction_type}</td>
              <td>{currencyFormat(item.dailyUsage)} {item.dailyLimit > 0 && item.dailyUsage >= item.dailyLimit &&
                <FaExclamation className={'text-danger'}/>}</td>
              <td>{item.dailyLimit > 0 ? currencyFormat(item.dailyLimit) : 'Sin límite'}</td>
              <td>{currencyFormat(item.monthlyUsage)} {item.monthlyLimit > 0 && item.monthlyUsage >= item.monthlyLimit &&
                <FaExclamation className={'text-danger'}/>}</td>
              <td>{item.monthlyLimit > 0 ? currencyFormat(item.monthlyLimit) : 'Sin límite'}</td>
            </tr>)))}
          </tbody>
        </Table>
      </div>}
    </>
  );
};
