import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { currencyFormat } from '../config';

export const UserBalancesSummary = ({ summary }) => {

  return (
    <>
      {!summary &&
        <Row><Col className="text-center"><Spinner animation="border" size="xl" variant="info"/></Col></Row>}
      {summary && <div>
        Lista por moneda de cuanto ha depositado, retirado, comprado y vendido vs peso (y rate promedio)
        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>Moneda</th>
            <th>Depositado</th>
            <th>Retirado</th>
            <th>Comprado</th>
            <th>Vendido</th>
          </tr>
          </thead>
          <tbody>
          {summary && Object.entries(summary).map(([ symbol, data ], k) => (<tr key={k}>
            <td>{symbol}</td>
            <td>{currencyFormat(data.deposit)}</td>
            <td>{currencyFormat(data.withdrawal)}</td>
            <td>{currencyFormat(data.bids.amount)} @ {currencyFormat(data.bids.wrate, 2)}</td>
            <td>{currencyFormat(data.asks.amount)} @ {currencyFormat(data.asks.wrate, 2)}</td>
          </tr>))}
          </tbody>
        </Table>
      </div>}
    </>
  );
};
