import moment from 'moment';
import { useEffect, useState } from 'react';
import { Row, Col, Tabs, Tab, Table, Alert, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FaArrowRight } from 'react-icons/fa';

import { currencyFormat } from '../config';
import './Retiro.css';
import {
  clearError, actionGetBalancesSummary,
  actionGetTradesList, clearTradesList,
  actionGetTransactionsList, clearTransactionsList, clearSummary,
} from '../actions/balancesSlice';
import {
  actionGetUserKYC,
  actionGetUserLimits,
  clearLimits,
  clearKYC,
} from '../actions/usersSlice';
import { actionGetWalletsBalances } from '../actions/hotwalletsSlice';

import { TradesList } from './TradesList';
import { TransactionsList } from './TransactionsList';
import { BalancesChart } from './BalancesChart';
import { UserBalancesSummary } from './UserBalancesSummary';
import { UserLimitsUsage } from './UserLimitsUsage';

const Retiro = ({ retiro }) => {
  const balancesError = useSelector((state) => state.balances.error);
  const summary = useSelector((state) => state.balances.summary);
  const trades = useSelector((state) => state.balances.trades);
  const transactions = useSelector((state) => state.balances.transactions);
  const kyc = useSelector((state) => state.users.kyc);
  const limits = useSelector((state) => state.users.limits);
  const walletsBalances = useSelector((state) => state.hotwallets.balances);
  const symbols = useSelector((state) => state.catalogs.symbols);
  const [ symbol, setSymbol ] = useState(false);
  const [ network, setNetwork ] = useState(false);
  const dispatch = useDispatch();

  const retiroOwner = retiro && retiro.owner;
  useEffect(() => {
    if (retiro && retiro.owner) {
      dispatch(actionGetBalancesSummary(retiro.owner));
      dispatch(actionGetUserKYC(retiro.owner));
      dispatch(actionGetWalletsBalances());
      dispatch(clearTradesList());
      dispatch(clearTransactionsList());
      dispatch(clearLimits());
      setSymbol(symbols.find(s => s.id === retiro.symbol));
    } else {
      dispatch(clearSummary());
    }
  }, [ retiroOwner, retiro ]);

  useEffect(() => {
    if (retiro && symbol && symbol.params) {
      if (symbol.params.chains) {
        let networkObj = symbol.params.chains.find(f => f.id === retiro.details.selectedNetwork);
        if (networkObj) {
          networkObj = { ...networkObj, label: symbol.params.addressLabels[retiro.details.selectedNetwork] };
          setNetwork(networkObj);
        } else {
          setNetwork(false);
        }
      } else {
        let networkObj = {
          label: symbol.name,
          parent: symbol.id,
        };
        setNetwork(networkObj);
      }
    } else {
      setNetwork(false);
    }
  }, [ symbol, retiro ]);

  console.log('KYC usuario', kyc)
  const handleTabChange = (tabName) => {
    if (tabName === 'trades' && !trades) {
      dispatch(actionGetTradesList(retiro.owner));
    }
    if (tabName === 'transacciones' && !transactions) {
      dispatch(actionGetTransactionsList(retiro.owner));
    }
    if (tabName === 'limites' && !limits) {
      dispatch(actionGetUserLimits(retiro.owner));
    }
  };

  if (!retiro && !summary) {
    return (<Alert variant="info">Por favor escoje un retiro para revisar</Alert>);
  }
  if (!retiro || !summary) {
    return (<Row><Col className="text-center"><Spinner animation="border" size="xl" variant="info"/></Col></Row>);
  }
  if (!symbol || !network) {
    return (<Alert variant="info">No puedo determinar la moneda que se esta usando</Alert>);
  }

  return (
    <>
      {balancesError.code > 0 && (
        <Alert
          variant="danger"
          dismissible
          onClose={() => dispatch(clearError())}
        >
          Hubo un error al ejecutar la operación: {balancesError.message}
        </Alert>
      )}
      <Row>
        <Col md={2}>Monto del retiro</Col>
        <Col>
          {currencyFormat(retiro.amount)} {retiro.symbol}
        </Col>
        <Col md={2}>Disponible</Col>
        <Col>{currencyFormat(walletsBalances[`${network.parent}:${symbol.name}`])}
          <small>{symbol.name} <FaArrowRight/> {network.label || network.parent}</small></Col>
      </Row>
      <Row>
        <Col md={2}>Fecha de emisión</Col>
        <Col>{moment(retiro.created_at).format()}</Col>
      </Row>
      <Row>
        <Col md={2}>Ordenante</Col>
        <Col>{retiro.details.nombreOrdenante}</Col>
        <Col md={2}>Beneficiario</Col>
        <Col>{retiro.details.nombreBeneficiario}</Col>
      </Row>
      <Row>
        <Col md={2}>Username/eMail</Col>
        <Col>{retiro.username}</Col>
        <Col md={2}>Institución</Col>
        <Col>{retiro.details.institucionContraparte}</Col>
      </Row>
      <Row>
        <Col md={2}>Red</Col>
        <Col>{network.label}</Col>
        <Col md={2}>Cuenta destino</Col>
        <Col>{retiro.details.address}</Col>
      </Row>

      <Row>
        <Col md={2}>Nivel de KYC</Col>
        <Col className="text-success fw-bold">{kyc.level}</Col>
        <Col md={2}>Descripción</Col>
        <Col>{retiro.description}</Col>
      </Row>
      {kyc && kyc.level > 0 &&
        <div>
          <h5 className="text-center">Datos de KYC Nivel 1</h5>
          <hr/>
          <Row>
            <Col md={2}>Nombre</Col>
            <Col>{kyc.level1.firstName} {kyc.level1.lastName}</Col>
            <Col md={2}>Teléfono</Col>
            <Col>({kyc.level1.mobileCountry}) {kyc.level1.mobile}</Col>
          </Row>
          <Row>
            <Col md={2}>Dirección</Col>
            <Col>{kyc.level1.address1.trim()}, {kyc.level1.address2.trim()}, {kyc.level1.address3.trim()}, {kyc.level1.zip}</Col>
          </Row>
          <Row>
            <Col md={2}>País / Estado</Col>
            <Col>{_.get(kyc.countryObj, 'name', 'ND')} / {_.get(kyc.stateObj, 'name', 'ND')} / {_.get(kyc.city, 'name', 'ND')}</Col>
          </Row>
        </div>}

      <Row>
        <Tabs defaultActiveKey="general" id="uncontrolled-tab-example" className="mb-3"
              onSelect={(e) => handleTabChange(e)}>
          <Tab eventKey={'general'} title={'Visión General'}>
            <UserBalancesSummary summary={summary}/>
          </Tab>
          <Tab eventKey={'limites'} title={'Ver limites'}>
            <UserLimitsUsage userLimits={limits}/>
          </Tab>
          <Tab eventKey={'trades'} title={'Ver trades'}>
            <TradesList trades={trades}/>
          </Tab>
          <Tab eventKey={'transacciones'} title={'Ver transacciones'}>
            <TransactionsList transactions={transactions}/>
          </Tab>
          <Tab eventKey={'balanceschart'} title={'Balances'}>
            <BalancesChart uid={retiroOwner}/>
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

export default Retiro;
