import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container>
      <Card>
        <Card.Header>
          <Card.Title>Página no encontrada</Card.Title>
        </Card.Header>
        <Card.Body>La página que estas buscando no existe</Card.Body>
        <Card.Footer>
          <Link to="/">Regresar al inicio</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
export default NotFound;
