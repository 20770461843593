import { useDispatch } from "react-redux";
import {
  Nav,
  Navbar,
  NavItem,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { actionLogout } from "../actions/authSlice";

const NavegacionPrincipal = () => {
  const dispatch = useDispatch();
  return (
    <Navbar variant="dark" bg="primary">
      <Nav>
        <Navbar.Brand as={Link} to="/">
          Domitai
        </Navbar.Brand>
        <Form className="d-flex">
          <FormControl type="text" placeholder="Search" className="mr-sm-2" />
          <Button variant="success">Search</Button>
        </Form>
        <NavItem href="me">
          <Nav.Link as={Link} to="me">
            Perfil
          </Nav.Link>
        </NavItem>
        <NavItem href="/login">
          <Nav.Link
            as={Link}
            to="/login"
            onClick={() => dispatch(actionLogout())}
          >
            Salir
          </Nav.Link>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default NavegacionPrincipal;
