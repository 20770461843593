import { createSlice } from '@reduxjs/toolkit';
import { socket } from '../contexts/Websocket';
import * as _ from 'lodash';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    error: { code: 0, message: false },
    user: false, kyc: false, limits: false, balances: false,
  },
  reducers: {
    setError: (state, action) => {
      state.error.code = action.payload.status;
      state.error.message =
        action.payload.data.message || action.payload.statusText;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    clearError: (state) => {
      state.error = { code: 0, message: false };
      state.isLogged = !!(state.uidp && state.jwt);
    },
    clearKYC: (state) => {
      state.kyc = false;
    },
    setKYC: (state, action) => {
      state.kyc = action.payload.data.payload;
    },
    clearLimits: (state) => {
      state.limits = false;
    },
    setLimits: (state, action) => {
      state.limits = action.payload.data.payload;
    },
    clearBalances: (state) => {
      state.balances = false;
    },
    setBalances: (state, action) => {
      state.balances = action.payload.data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setError, clearError, clearKYC, setKYC, clearLimits, setLimits, clearBalances, setBalances } = usersSlice.actions;
export default usersSlice.reducer;

export function actionGetUserKYC(id) {
  const url = `/api/v2/reportes/kyc/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    dispatch(clearKYC());
    socket.emit('GET', { url, ...options }, (response) => {
      console.log('KYC', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
        dispatch(setKYC(response));
      }
    });
  };
}

export function actionGetUserLimits(id) {
  const url = `/api/v2/reportes/resumen-limites-usuario/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    dispatch(clearLimits());
    socket.emit('GET', { url, ...options }, (response) => {
      console.log('LIMITS', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
        dispatch(setLimits(response));
      }
    });
  };
}

export function actionGetUserBalances(id) {
  const url = `/api/v2/reportes/resumen-balances-usuario/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    dispatch(clearBalances());
    socket.emit('GET', { url, ...options }, (response) => {
      console.log('BALANCES USUARIO', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
        dispatch(setBalances(response));
      }
    });
  };
}

