import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import _ from 'lodash';
import { WS_BASE } from '../config';

import { setHeartbeat } from '../actions/counterSlice';
import { actionRefreshJWT } from '../actions/authSlice';
import { actionGetPendingWithdrawals } from '../actions/withdrawalListSlice';
import { Alert } from 'react-bootstrap';
import { actionGetBlockedDeposits } from '../actions/depositsSlice';

export const socket = io(WS_BASE, {
  withCredentials: false,
  reconnectionDelayMax: 10000,
  autoConnect: true,
  forceNew: false,
  secure: false,
  transports: [ 'websocket', 'polling' ],
  rejectUnauthorized: false,
});

export const WebSocketContext = createContext();

let initialized = false;

const Websocket = ({ children }) => {
  const initialStateError = { code: 0, message: false };
  const [ error, setError ] = useState(initialStateError);
  const isLogged = useSelector((state) => state.auth.isLogged);
  const dispatch = useDispatch();

  const JOIN = (url) => {
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
    return async (dispatch) => {
      socket.emit('JOIN', { url, ...options }, (response) => {
        console.log('JOIN', response);
        if (!response.data.success) {
          //dispatch(setError(response));
        } else {
          //dispatch(setPendingWithdrawalsList(response));
          //dispatch(clearError());
        }
      });
    };
  };

  useEffect(() => {
    if (!initialized) {
      socket.on('connect', async () => {
        console.info('Connected to Domitai', socket.id);
        setError(initialStateError);
        if (isLogged) {
          console.log('CONNECT isLogged: conectandose a canales privados');
          dispatch(JOIN('admin'));
        }
      });

      socket.on('reconnect', async () => {
        console.info('Reconnected to Domitai', socket.id);
      });

      socket.on('disconnect', () => {
        console.info('Reconecting to Domitai', socket.id);
        setError({ code: 500, message: 'Desconectado del servidor' });
      });

      socket.on('heartbeat', async (data) => {
        dispatch(setHeartbeat(data));
        dispatch(actionRefreshJWT());
      });

      Object.entries({
        'events:///api/v2/reportes/retiros-pendientes': actionGetPendingWithdrawals,
        'events:///api/v2/reportes/depositos-bloqueados': actionGetBlockedDeposits,
      })
        .forEach(([ evento, funcion ]) => {
          socket.on(
            evento,
            async (data) => {
              console.log('Recibio evento de nuevas tx', data);
              if (Array.isArray(funcion)) {
                funcion.forEach(fn => dispatch(fn()));
              } else {
                dispatch(funcion());
              }
            },
          );
        });

      initialized = true;
    }
  });

  useEffect(() => {
    if (isLogged) {
      console.log('isLogged: conectandose a canales privados');
      dispatch(JOIN('admin'));
    } else {
      console.log('NOT isLogged: desconectandose a canales privados');
      console.log('TODO: Desconectar de canales privados');
    }
  }, [ isLogged ]);

  return (
    <WebSocketContext.Provider value={socket}>
      {error.code > 0 && <Alert variant="danger">Error {error.message}</Alert>}
      {children}
    </WebSocketContext.Provider>
  );
};

export default Websocket;
