import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../contexts/Websocket";

export const withdrawalListSlice = createSlice({
  name: "withdrawalList",
  initialState: {
    error: { code: 0, message: false },
    pendingWitdrawalsList: false,
  },
  reducers: {
    setError: (state, action) => {
      state.error.code = action.payload.status;
      state.error.message =
        action.payload.data.message || action.payload.statusText;
    },
    clearError: (state) => {
      state.error = { code: 0, message: false };
    },
    setPendingWithdrawalsList: (state, action) => {
      state.pendingWitdrawalsList = action.payload.data.payload || [
        { id: 1, amount: 123, owner: "abc-def", symbol: "ABC" },
        { id: 2, amount: 2300, owner: "abc-def", symbol: "MXN" },
      ];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setError, clearError, setPendingWithdrawalsList } =
  withdrawalListSlice.actions;
export default withdrawalListSlice.reducer;

export function actionGetPendingWithdrawals() {
  const url = `/api/v2/reportes/retiros-pendientes`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("domitai_jwt")}`,
      },
    };
  return async (dispatch) => {
    socket.emit("GET", { url, ...options }, (response) => {
      console.log("LISTA", response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(setPendingWithdrawalsList(response));
        dispatch(clearError());
      }
    });
  };
}
