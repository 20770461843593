import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import counterSlice from "../actions/counterSlice";
import authSlice from "../actions/authSlice";
import withdrawalListSlice from "../actions/withdrawalListSlice";
import withdrawalSlice from "../actions/withdrawalSlice";
import balancesSlice from "../actions/balancesSlice";
import usersSlice from "../actions/usersSlice";
import hotwalletsSlice from "../actions/hotwalletsSlice";
import catalogsSlice from "../actions/catalogsSlice";
import depositsSlice from "../actions/depositsSlice";

export default configureStore(
  {
    reducer: {
      counter: counterSlice,
      auth: authSlice,
      withdrawalList: withdrawalListSlice,
      withdrawal: withdrawalSlice,
      balances: balancesSlice,
      users: usersSlice,
      hotwallets: hotwalletsSlice,
      catalogs: catalogsSlice,
      deposits: depositsSlice,
    },
  },
  applyMiddleware(thunk)
);
