import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../contexts/Websocket";

export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState: {
    error: { code: 0, message: false },
  },
  reducers: {
    setError: (state, action) => {
      state.error.code = action.payload.status;
      state.error.message =
        action.payload.data.message || action.payload.statusText;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    clearError: (state) => {
      state.error = { code: 0, message: false };
      state.isLogged = !!(state.uidp && state.jwt);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setError, clearError } = withdrawalSlice.actions;
export default withdrawalSlice.reducer;

export function actionApproveWithdrawal(id) {
  const url = `/api/v2/retiro/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("domitai_jwt")}`,
      },
    };
  return async (dispatch) => {
    socket.emit("PUT", { url, ...options }, (response) => {
      console.log("DEVOLVIO", response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
      }
    });
  };
}

export function actionRejectWithdrawal(id) {
  const url = `/api/v2/retiro/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("domitai_jwt")}`,
      },
    };
  return async (dispatch) => {
    socket.emit("DELETE", { url, ...options }, (response) => {
      console.log("DEVOLVIO", response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
      }
    });
  };
}
