import { createSlice } from '@reduxjs/toolkit';
import { socket } from '../contexts/Websocket';
import * as _ from 'lodash';

export const balancesSlice = createSlice({
  name: 'balances',
  initialState: {
    error: { code: 0, message: false },
    summary: false, trades: false, transactions: false,
  },
  reducers: {
    setError: (state, action) => {
      state.error.code = action.payload.status;
      state.error.message =
        action.payload.data.message || action.payload.statusText;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    clearError: (state) => {
      state.error = { code: 0, message: false };
      state.isLogged = !!(state.uidp && state.jwt);
    },
    clearSummary: (state) => {
      state.summary = false;
    },
    setSummary: (state, action) => {
      const payload = action.payload.data.payload;
      const summary = {},
        initialState = { deposit: 0, withdrawal: 0, bids: { amount: 0, wrate: 1 }, asks: { amount: 0, wrate: 1 } };
      payload.transactions.forEach(t => {
        if (!summary[t.symbol]) {
          summary[t.symbol] = { ...initialState };
        }
        summary[t.symbol][t.transaction_type] = t.amount;
      });
      payload.trades.forEach(t => {
        if (!summary[t.base]) {
          summary[t.base] = { ...initialState };
        }
        summary[t.base][t.book] = _.pick(t, [ 'amount', 'total', 'wrate' ]);
      });
      payload.balances.forEach(t => {
        if (!summary[t.symbol]) {
          summary[t.symbol] = { ...initialState };
        }
        summary[t.symbol].balances = _.pick(t, [ 'available', 'balance', 'on_collateral', 'on_orders', 'reserved' ]);
      });

      state.summary = summary;
    },
    clearTradesList: (state) => {
      state.trades = false;
    },
    setTradesList: (state, action) => {
      state.trades = action.payload.data.payload;
    },
    clearTransactionsList: (state) => {
      state.transactions = false;
    },
    setTransactionsList: (state, action) => {
      state.transactions = action.payload.data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setError,
  clearError,
  setSummary,
  clearSummary,
  setTradesList,
  clearTradesList,
  setTransactionsList,
  clearTransactionsList,
} = balancesSlice.actions;
export default balancesSlice.reducer;

export function actionGetBalancesSummary(id) {
  const url = `/api/v2/reportes/resumen-operaciones-usuario/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    dispatch(clearSummary());
    socket.emit('GET', { url, ...options }, (response) => {
      console.log('SUMMARY', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
        dispatch(setSummary(response));
      }
    });
  };
}

export function actionGetTradesList(id) {
  const url = `/api/v2/reportes/resumen-trades-usuario/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    dispatch(clearTradesList());
    socket.emit('GET', { url, ...options }, (response) => {
      console.log('DEVOLVIO', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
        dispatch(setTradesList(response));
      }
    });
  };
}

export function actionGetTransactionsList(id) {
  const url = `/api/v2/reportes/resumen-transactions-usuario/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    dispatch(clearTransactionsList());
    socket.emit('GET', { url, ...options }, (response) => {
      console.log('DEVOLVIO', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
        dispatch(setTransactionsList(response));
      }
    });
  };
}

