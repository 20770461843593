import { createSlice } from '@reduxjs/toolkit';
import { socket } from '../contexts/Websocket';
import * as crypto from 'crypto-js';
import { KJUR } from 'jsrsasign';
import * as jose from 'jose';


export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLogged: !!(
      localStorage.getItem('domitai_uidp') &&
      localStorage.getItem('domitai_jwt')
    ),
    email: '',
    uidp: localStorage.getItem('domitai_uidp'),
    jwt: localStorage.getItem('domitai_jwt'),
    error: { code: 0, message: false },
  },
  reducers: {
    setJWT: (state, action) => {
      const JWT = action.payload.data.payload.token;
      localStorage.setItem('domitai_jwt', JWT);
      state.jwt = JWT;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    setUIDP: (state, action) => {
      const UIDP = action.payload;
      localStorage.setItem('domitai_uidp', UIDP);
      state.uidp = UIDP;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    unsetJWT: (state) => {
      localStorage.removeItem('domitai_jwt');
      state.jwt = false;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    unsetUIDP: (state) => {
      localStorage.removeItem('domitai_uidp');
      state.uidp = false;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    setError: (state, action) => {
      state.error.code = action.payload.status;
      state.error.message =
        action.payload.data.message || action.payload.statusText;
      state.isLogged = !!(state.uidp && state.jwt);
    },
    clearError: (state) => {
      state.error = { code: 0, message: false };
      state.isLogged = !!(state.uidp && state.jwt);
    },
    noop: (state) => {

    },
  },
});

// Action creators are generated for each case reducer function
export const { setError, setJWT, setUIDP, unsetJWT, unsetUIDP, clearError, noop } =
  authSlice.actions;
export default authSlice.reducer;

export function actionLogin(username, password) {
  const uidp = crypto
    .HmacSHA256(username, password)
    .toString(crypto.enc.Base64);
  const url = '/api/auth',
    options = { body: { username, uidp } };
  return async (dispatch) => {
    socket.emit('POST', { url, ...options }, (response) => {
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(setJWT(response));
        dispatch(setUIDP(uidp));
        dispatch(clearError());
      }
    });
  };
}

export function actionLogout() {
  const url = '/api/auth',
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
      query: {
        token: localStorage.getItem('domitai_jwt'),
        uidp: localStorage.getItem('domitai_uidp'),
      },
    };
  return async (dispatch) => {
    socket.emit('DELETE', { url, ...options }, (response) => {
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(unsetJWT());
        dispatch(unsetUIDP());
        dispatch(clearError());
      }
    });
  };
}

export function actionRefreshJWT() {
  const token = localStorage.getItem('domitai_jwt');
  const uidp = localStorage.getItem('domitai_uidp');
  const valido = token && uidp && KJUR.jws.JWS.verifyJWT(token, uidp, { alg: [ 'HS256' ], gracePeriod: 60 * 5 });
  const expirando = token && uidp && (jose.decodeJwt(token).exp - (new Date().getTime() / 1000)) < 600;

  const url = '/api/auth',
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
      query: {
        token: localStorage.getItem('domitai_jwt'),
        uidp: localStorage.getItem('domitai_uidp'),
      },
    };
  return async (dispatch) => {
    if (!valido && !expirando) {
      dispatch(unsetJWT());
      dispatch(unsetUIDP());
    } else if (expirando) {
      console.log('EXPIRANDO');
      socket.emit('GET', { url, ...options }, (response) => {
        console.log('el get', response)
        if (!response.data.success) {
          dispatch(setError(response));
          dispatch(unsetJWT());
          dispatch(unsetUIDP());
        } else {
          dispatch(setJWT(response));
          dispatch(clearError());
        }
      });
    } else {
      dispatch(noop());
    }
  };
}

