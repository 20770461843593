import _ from 'lodash';
import { useState, useEffect } from 'react';
import { Col, Pagination, Row, Spinner, Table, Popover, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { currencyFormat } from '../config';
import moment from 'moment-timezone';

export const TransactionsList = ({ transactions }) => {
  const [ pageNumber, setPageNumber ] = useState(0);
  const [ itemsPerPage, setItemsPerPage ] = useState(10);
  const [ popoverShown, setPopoverShown ] = useState({});

  useEffect(() => {
    return () => {
      setPopoverShown({});
    };
  }, []);

  const popover = (txid) => {
    const tx = transactions.find(tx => tx.id === txid);
    return (
      <Popover id="popover-basic" onMouseOver={() => setPopoverShown({ ...popoverShown, [txid]: true })}
               onMouseOut={() => setPopoverShown({ ...popoverShown, [txid]: undefined })}>
        <Popover.Header as="h3">Transacción #{tx.id}</Popover.Header>
        <Popover.Body>
          <Table striped bordered hover size="sm">
            <tbody>
            <tr>
              <td>Monto</td>
              <td>{currencyFormat(tx.amount)} <small>{tx.symbol}</small></td>
            </tr>
            <tr>
              <td>Descripción</td>
              <td style={{ wordBreak: 'break-all' }}>{tx.description}</td>
            </tr>
            {tx.transaction_status === 'completed' && <tr>
              <td>Referencia</td>
              <td style={{ wordBreak: 'break-all' }}>{tx.reference.split(':')[0]}</td>
            </tr>}
            {tx.details.address && <tr>
              <td>Destino</td>
              <td style={{ wordBreak: 'break-all' }}>{tx.details.address}</td>
            </tr>}
            {tx.details.nombreOrdenante && <tr>
              <td>Ordenante</td>
              <td style={{ wordBreak: 'break-all' }}>{tx.details.nombreOrdenante}</td>
            </tr>}
            {tx.details.nombreBeneficiario && <tr>
              <td>Beneficiario</td>
              <td style={{ wordBreak: 'break-all' }}>{tx.details.nombreBeneficiario}</td>
            </tr>}
            </tbody>
          </Table>
        </Popover.Body>
      </Popover>
    );
  };

  return (<>
    {!transactions &&
      <Row><Col className="text-center"><Spinner animation="border" size="xl" variant="info"/></Col></Row>}
    {transactions && <div>
      Lista de sus ultimas 200 transacciones
      <Pagination>
        <Pagination.First onClick={() => setPageNumber(0)}/>
        <Pagination.Prev onClick={() => setPageNumber(Math.max(0, pageNumber - 1))}/>
        {_.range(0, Math.ceil(transactions.length / itemsPerPage)).map((k) => (
          <Pagination.Item key={k} active={pageNumber === k}
                           onClick={() => setPageNumber(k)}>{k + 1}</Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setPageNumber(Math.min(Math.ceil(transactions.length / itemsPerPage) - 1, pageNumber + 1))}/>
        <Pagination.Last
          onClick={() => setPageNumber(Math.ceil(transactions.length / itemsPerPage) - 1)}/>
      </Pagination>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Operación</th>
          <th>Monto</th>
          <th>Referencia</th>
          <th>Estatus</th>
          <th>Creado</th>
          <th>Ult. Cambio</th>
        </tr>
        </thead>
        <tbody>
        {transactions && transactions.length > 0 && _.chunk(Object.values(transactions), itemsPerPage)[pageNumber].map(v => (
          <OverlayTrigger
            show={popoverShown[v.id]} trigger={[ 'hover', 'focus' ]} placement={'auto-start'}
            overlay={popover(v.id)} delay={250} key={v.id}>
            <tr>
              <td>{v.transaction_type}</td>
              <td className="text-end">{currencyFormat(v.amount)}<small>{v.symbol}</small></td>
              <td>
                <div className="truncate"
                     style={{ width: '20rem' }}>{v.transaction_status === 'completed' ? v.reference.split(':')[0] : 'No disponible'}</div>
              </td>
              <td>{v.transaction_status}</td>
              <td>{moment(v.created_at).format('Y-MM-DD HH:mm')}</td>
              <td>{moment(v.updated_at).format('Y-MM-DD HH:mm')}</td>
            </tr>
          </OverlayTrigger>))}
        </tbody>
      </Table>
    </div>}
  </>);
};
