import { createSlice } from '@reduxjs/toolkit';
import { socket } from '../contexts/Websocket';

export const depositsSlice = createSlice({
  name: 'deposits',
  initialState: {
    error: { code: 0, message: false },
    blockedDepositsList: false,
  },
  reducers: {
    setError: (state, action) => {
      state.error.code = action.payload.status;
      state.error.message =
        action.payload.data.message || action.payload.statusText;
    },
    clearError: (state) => {
      state.error = { code: 0, message: false };
    },
    setBlockedDepositsList: (state, action) => {
      state.blockedDepositsList = (action.payload.data.payload || []).filter(f => f.details.hasOwnProperty('lockCause') && f.details.lockCause !== 'Admin Blocked');
    },
  },
});

// Action creators are generated for each case reducer function
export const { setError, clearError, setBlockedDepositsList } =
  depositsSlice.actions;
export default depositsSlice.reducer;

export function actionGetBlockedDeposits() {
  const url = `/api/v2/reportes/depositos-bloqueados`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    socket.emit('GET', { url, ...options }, (response) => {
      console.log('DEPOSITOS BLOQUEADOS', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(setBlockedDepositsList(response));
        dispatch(clearError());
      }
    });
  };
}

export function actionApproveDeposit(id) {
  const url = `/api/v2/deposito/bloqueo/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    socket.emit('PUT', { url, ...options }, (response) => {
      console.log('DEVOLVIO', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
      }
    });
  };
}

export function actionRejectDeposit(id) {
  const url = `/api/v2/deposito/bloqueo/${id}`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('domitai_jwt')}`,
      },
    };
  return async (dispatch) => {
    socket.emit('DELETE', { url, ...options }, (response) => {
      console.log('DEVOLVIO', response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(clearError());
      }
    });
  };
}
