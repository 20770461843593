import { Col, Nav, Navbar, NavItem, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const MenuLateral = () => {
  return (
    <Navbar variant="light" bg="light" expand="lg">
      <Col>
        <Row>
          <Nav>
            <NavItem href="estadisticas">
              <Nav.Link as={Link} to="estadisticas">
                Estadísticas de Domitai
              </Nav.Link>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <Nav>
            <NavItem href="lista_retiros">
              <Nav.Link as={Link} to="lista_retiros">
                Retiros Pendientes
              </Nav.Link>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <Nav>
            <NavItem href="lista_depositos_bloqueados">
              <Nav.Link as={Link} to="lista_depositos_bloqueados">
                Depósitos Bloqueados
              </Nav.Link>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <Nav>
            <NavItem href="usuarios">
              <Nav.Link as={Link} to="usuarios">
                Administración de Usuarios
              </Nav.Link>
            </NavItem>
          </Nav>
        </Row>
      </Col>
    </Navbar>
  );
};

export default MenuLateral;
