import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider, Col } from "react-bootstrap";
import "./App.css";

import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Me from "./containers/Me";
import ListaRetiros from "./containers/ListaRetiros";
import ListaDepositosBloqueados from './containers/ListaDepositosBloqueados';
import { Estadisticas } from './containers/Estadisticas';

function App() {
  const isLogged = useSelector((state) => state.auth.isLogged);

  return (
    <ThemeProvider>
      <Col md={12}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="dashboard"
              element={isLogged ? <Dashboard /> : <Navigate to="/login" />}
            >
              <Route path="me" element={<Me />} />
              <Route path="estadisticas" element={<Estadisticas />} />
              <Route path="usuarios" element={<Estadisticas />} />
              <Route path="lista_retiros" element={<ListaRetiros />} />
              <Route path="lista_depositos_bloqueados" element={<ListaDepositosBloqueados />} />
            </Route>
            <Route
              path="login"
              element={isLogged ? <Navigate to="/dashboard" /> : <Login />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Col>
    </ThemeProvider>
  );
}

export default App;
