import _ from 'lodash';
import { useState } from 'react';
import { Col, Pagination, Row, Spinner, Table } from 'react-bootstrap';
import { currencyFormat } from '../config';
import moment from 'moment';

export const TradesList = ({ trades }) => {
  const [ pageNumber, setPageNumber ] = useState(0);
  const [ itemsPerPage, setItemsPerPage ] = useState(10);

  return (<>
    {!trades &&
      <Row><Col className="text-center"><Spinner animation="border" size="xl" variant="info"/></Col></Row>}
    {trades && <div>
      Lista de sus ultimos 200 trades
      <Pagination>
        <Pagination.First onClick={() => setPageNumber(0)}/>
        <Pagination.Prev onClick={() => setPageNumber(Math.max(0, pageNumber - 1))}/>
        {_.range(0, Math.ceil(trades.length / itemsPerPage)).map((k) => (
          <Pagination.Item key={k} active={pageNumber === k}
                           onClick={() => setPageNumber(k)}>{k + 1}</Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setPageNumber(Math.min(Math.ceil(trades.length / itemsPerPage) - 1, pageNumber + 1))}/>
        <Pagination.Last
          onClick={() => setPageNumber(Math.ceil(trades.length / itemsPerPage) - 1)}/>
      </Pagination>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Operación</th>
          <th>Monto</th>
          <th>Precio</th>
          <th>Costo</th>
          <th>Fecha</th>
        </tr>
        </thead>
        <tbody>
        {trades && trades.length > 0 && _.chunk(Object.values(trades), itemsPerPage)[pageNumber].map(v => <tr
          key={v.id}>
          <td>
            <div className={`${v.book}`}>{v.book === 'asks' ? 'Venta' : 'Compra'}</div>
          </td>
          <td className="text-end">{currencyFormat(v.amount)}<small>{v.base}</small></td>
          <td className="text-end">{currencyFormat(v.rate)}<small>{v.quote}</small></td>
          <td className="text-end">{currencyFormat(v.total)}<small>{v.quote}</small></td>
          <td>{moment(v.created_at).format('Y-M-D HH:mm:ss')}</td>
        </tr>)}
        </tbody>
      </Table>
    </div>}
  </>);
};
