import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../contexts/Websocket";

export const hotwalletsSlice = createSlice({
  name: "hotwallets",
  initialState: {
    error: { code: 0, message: false },
    balances: false,
  },
  reducers: {
    setError: (state, action) => {
      state.error.code = action.payload.status;
      state.error.message =
        action.payload.data.message || action.payload.statusText;
    },
    clearError: (state) => {
      state.error = { code: 0, message: false };
    },
    setBalances: (state, action) => {
      state.balances = action.payload.data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setError, clearError, setBalances } = hotwalletsSlice.actions;
export default hotwalletsSlice.reducer;

export function actionGetWalletsBalances() {
  const url = `/api/v2/reportes/balances`,
    options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("domitai_jwt")}`,
      },
    };
  return async (dispatch) => {
    socket.emit("GET", { url, ...options }, (response) => {
      console.log("HOTWALLETS", response);
      if (!response.data.success) {
        dispatch(setError(response));
      } else {
        dispatch(setBalances(response));
        dispatch(clearError());
      }
    });
  };
}
