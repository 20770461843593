// https://www.membug.com/note/72f48c5bbab40
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { socket } from "../contexts/Websocket";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
    ticker: { bid: 0 },
    heartbeat: 0,
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setTicker: (state, action) => {
      console.log("TICKER", action.payload);
      state.ticker = action.payload;
    },
    setHeartbeat: (state, action) => {
      //console.log("HB", action.payload);
      state.heartbeat = action.payload;
    },
    setGETResult: (state, action) => {
      console.log("GET Action", action.payload);
    },
    setPOSTResult: (state, action) => {
      console.log("POST Action", action.payload);
    },
    setError: (state, action) => {
      console.error("TICKER", action);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  setTicker,
  setError,
  setHeartbeat,
  setGETResult,
  setPOSTResult,
} = counterSlice.actions;

export default counterSlice.reducer;

const api = axios.get("https://domitai.com/api/ticker/btc_mxn");

export function getTicker() {
  return async (dispatch) => {
    api
      .then(({ data }) => {
        dispatch(setTicker(data.payload));
      })
      .catch((err) => {
        dispatch(setError());
      });
  };
}

export function getTickerIO() {
  const url = "/api/ticker/usdt_mxn",
    options = {};
  return async (dispatch) => {
    socket.emit("GET", { url, ...options }, (data) => {
      dispatch(setTicker(data.data.payload));
    });
  };
}

export function GET(url, options) {
  return async (dispatch) => {
    socket.emit("GET", { url, ...options }, (data) => {
      console.log("GETFULL", data);
      dispatch(setGETResult(data));
    });
  };
}

export function POST(url, options) {
  return async (dispatch) => {
    console.log("enviando", options, url);
    socket.emit("POST", { url, ...options }, (data) => {
      console.log("RECIBIENDO", data);
      dispatch(setPOSTResult(data));
    });
  };
}
