import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LineChart, Tooltip, XAxis, YAxis, Line, CartesianGrid, ResponsiveContainer, Legend } from 'recharts';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';

import {
  actionGetUserBalances, clearBalances,
} from '../actions/usersSlice';


export const BalancesChart = ({ uid }) => {
  const balances = useSelector((state) => state.users.balances);
  const [ width, setWidth ] = useState(0);
  const [ data, setData ] = useState([]);
  const [ dataKeys, setDataKeys ] = useState([]);
  const dispatch = useDispatch();
  const chartColors = {
    BTC: '#ff7300',
    MXN: '#aaff00',
    USDT: '#387908',
    ETH: '#ffaaff',
    DOGE: '#747cc6',
    LTC: '#a6e2d9',
    TUSD: '#31419e',
    BCH: '#d846e6',
    RDD: '#ff0000',
  };

  useEffect(() => {
    if (uid) {
      dispatch(actionGetUserBalances(uid));
    } else {
      dispatch(clearBalances());
    }
  }, [ uid ]);

  useEffect(() => {
    if (balances) {
      const data = [], dKeys = _(balances).groupBy('symbol').keys().value();
      setWidth(0);
      setDataKeys(dKeys);
      let item = {};
      Object.entries(_.groupBy(balances, 'created_at')).forEach(([ name, items ]) => {
        item = { name: moment.unix(Number(name) / 1000).format('Y-MM-DD HH:mm:ss') };
        items.forEach(d => {
          item[d.symbol] = Number(d.balance).toFixed(8);
        });
        dKeys.forEach(k => item[k] = item[k] || 0);
        data.push(item);
      });
      setData(data);
      console.log('BALANCE', data);
    } else {
      setData([]);
    }
  }, [ balances ]);
  return (<div style={{ border: '1px solid red' }}>
    {!data && <Alert variant="warning">Sin datos</Alert>}
    {data && data.length && dataKeys && dataKeys.length && <ResponsiveContainer width={'95%'} height={300}>
      <LineChart
        width={400}
        height={300}
        data={data}
        margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
      >
        <XAxis dataKey="name"/>
        <Tooltip/>
        <Legend verticalAlign="top" height={36}/>
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3"/>
        {dataKeys && dataKeys.map((dataKey, y) => (
          <YAxis type="number" dataKey={dataKey} stroke={chartColors[dataKey] || '#000000'}
                 domain={[ 0, dataMax => dataMax > 0 ? (dataMax * 1.1) : 1 ]}
                 yAxisId={y} hide={true}/>
        ))}
        {dataKeys && dataKeys.map((dataKey, y) => (
          <Line type="monotone" dataKey={dataKey} stroke={chartColors[dataKey] || '#000000'} yAxisId={y} key={y}/>
        ))}
      </LineChart>
    </ResponsiveContainer>
    }
  </div>);
};
